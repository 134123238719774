import { default as customersqoboWSpkv3Meta } from "/home/runner/work/cdp-dashboard/cdp-dashboard/pages/business_entities/[business_entity_uuid]/customers.vue?macro=true";
import { default as _91uuid_93IxUqO2G4uRMeta } from "/home/runner/work/cdp-dashboard/cdp-dashboard/pages/customers/[uuid].vue?macro=true";
export default [
  {
    name: "business_entities-business_entity_uuid-customers",
    path: "/business_entities/:business_entity_uuid()/customers",
    component: () => import("/home/runner/work/cdp-dashboard/cdp-dashboard/pages/business_entities/[business_entity_uuid]/customers.vue")
  },
  {
    name: "customers-uuid",
    path: "/customers/:uuid()",
    component: () => import("/home/runner/work/cdp-dashboard/cdp-dashboard/pages/customers/[uuid].vue")
  }
]