<script setup lang="ts">
import { NuxtLayout, NuxtPage, NuxtLoadingIndicator } from '#components'
import { useHead, useScriptEventPage } from '#imports'
import { getDataLayer } from '~/composables/useDataLayer'

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - STORES` : '顧客管理 - STORES'
  },
})

// GTM デフォルトのページビュー相当のトリガーである All Pages では以下の問題があるため、nuxt script の api を使ってページビューをトリガーする
//  - All Pages の前に 事業者 id などの情報を dataLayer に push したいがうまくいかない
//  - SPA のページビューは All Pages ではトリガーされない
// see: https://scripts.nuxt.com/scripts/tracking/google-tag-manager#guide-sending-page-events
useScriptEventPage((ctx) => {
  getDataLayer().push({
    event: 'pageview',
    title: ctx.title,
    path: ctx.path,
  })
})
</script>

<template>
  <NuxtLayout>
    <NuxtLoadingIndicator />
    <NuxtPage />
  </NuxtLayout>
</template>
