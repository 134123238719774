import revive_payload_client_GIuvrEEPGv from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_LJJRQGiQbK from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_tQ9JnWNxv7 from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_1fSIRWT0UG from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_Lh3ugZydDg from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_q6d7YIoqiI from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_1zWLazHZEx from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_zbacBAXhj0 from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/cdp-dashboard/cdp-dashboard/.nuxt/components.plugin.mjs";
import prefetch_client_CForkABUjX from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt@3.14.1592_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_eslint@8.57.0_ioredis_r6nfihmxj42k7m3mnhw7atyj7e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_sk5N5zwMVn from "/home/runner/work/cdp-dashboard/cdp-dashboard/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.7.8_encoding@0.1.13_graphql-t_hxogz2zgr2sy5vgjdsspd3m7ny/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/sentry.client.ts";
import set_graphql_host_client_hEFjBexpur from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/set-graphql-host.client.ts";
import toasts_client_unuLMHjfa9 from "/home/runner/work/cdp-dashboard/cdp-dashboard/plugins/toasts.client.ts";
export default [
  revive_payload_client_GIuvrEEPGv,
  unhead_LJJRQGiQbK,
  router_tQ9JnWNxv7,
  payload_client_1fSIRWT0UG,
  navigation_repaint_client_Lh3ugZydDg,
  check_outdated_build_client_q6d7YIoqiI,
  chunk_reload_client_1zWLazHZEx,
  plugin_zbacBAXhj0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_CForkABUjX,
  plugin_sk5N5zwMVn,
  sentry_client_shVUlIjFLk,
  set_graphql_host_client_hEFjBexpur,
  toasts_client_unuLMHjfa9
]